import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="divine"></a><h2>Divine</h2>
    <a id="new-spells"></a><h3>NEW SPELLS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <h2 style={{
      "marginTop": "-10px",
      "marginBottom": "0px"
    }}><span className="subtitle">In Alphabetical Order</span></h2>
    <table className="spell-list" border="0" cellPadding="2" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "20%"
          }}>Spell</th>
          <th style={{
            "width": "12%"
          }}>School</th>
          <th style={{
            "width": "12%"
          }}>Subschool</th>
          <th style={{
            "width": "17%"
          }}>Descriptor</th>
          <th style={{
            "width": "25%"
          }}>Level</th>
          <th style={{
            "width": "12%"
          }}>Components</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#armor-of-darkness">Armor of Darkness</a></td>
          <td>Abjuration</td>
          <td></td>
          <td>Darkness</td>
          <td>Darkness 4</td>
          <td>V, S, DF</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#blacklight">Blacklight</a></td>
          <td>Evocation</td>
          <td></td>
          <td>Darkness</td>
          <td>Darkness 3, Sor/Wiz 3</td>
          <td>V, S, M</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#bolt-of-glory">Bolt of Glory</a></td>
          <td>Evocation</td>
          <td></td>
          <td>Good</td>
          <td>Glory 6</td>
          <td>V, S, DF</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#bolt-of-bedevilment">Bolts of Bedevilment</a></td>
          <td>Enchantment</td>
          <td></td>
          <td>Mind-Affecting</td>
          <td>Madness 5</td>
          <td>V, S</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#crown-of-glory">Crown of Glory</a></td>
          <td>Evocation</td>
          <td></td>
          <td></td>
          <td>Glory 8</td>
          <td>V, S, M, DF</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#genesis">Genesis</a></td>
          <td>Conjuration</td>
          <td>Creation</td>
          <td></td>
          <td>Creation 9</td>
          <td>V, S, M, XP</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#hardening">Hardening</a></td>
          <td>Transmutation</td>
          <td></td>
          <td></td>
          <td>Sor/Wiz 6, Artifice 7</td>
          <td>V, S</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#maddening-scream">Maddening Scream</a></td>
          <td>Enchantment</td>
          <td>Compulsion</td>
          <td>Mind-Affecting</td>
          <td>Sor/Wiz 8, Madness 8</td>
          <td>V</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "#579eb6"
            }} href="#status-greater">Status, Greater</a></td>
          <td>Divination</td>
          <td></td>
          <td></td>
          <td>Community 4</td>
          <td>V, S, DF</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#surelife">Surelife</a></td>
          <td>Abjuration</td>
          <td></td>
          <td></td>
          <td>Repose 8</td>
          <td>V, S, M</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#touch-of-madness">Touch of Madness</a></td>
          <td>Enchantment</td>
          <td></td>
          <td>Mind-Affecting</td>
          <td>Madness 2</td>
          <td>V, S</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#true-creation">True Creation</a></td>
          <td>Conjuration</td>
          <td>Creation</td>
          <td></td>
          <td>Creation 8</td>
          <td>V, S, M, XP</td>
        </tr>
      </tbody>
    </table>
    <h6><a id="armor-of-darkness"></a>Armor Of Darkness</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Abjuration [Darkness]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Darkness 4
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S , D F
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Creature touched
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 10 minutes/level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates (harmless)
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
(harmless)
    <p>The spell envelops the warded creature in a shroud of shadows.
The shroud can, if the caster desires, conceal the wearer&rsquo;s
features. In any case, it grants the recipient a +3 deflection bonus to
Armor Class plus an additional +1 for every four caster levels (maximum
bonus +8). The subject can see through the armor as if it did not exist
and is also afforded darkvision with a range of 60 feet. Finally, the
subject gains a +2 bonus on saving throws against any holy, good, or
light spells or effects.Undead creatures that are subjects of armor of
darkness also gain +4 <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monsterTypes.html#turn-resistance">turn resistance</a>.</p>
    <h6><a id="blacklight"></a>Blacklight</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Evocation [Darkness]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Darkness 3,
Sor/Wiz 3
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S , M
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> A 20-ft.-radius
emanation centered on a creature, object, or point in space
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
(D)
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates or none (object)
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes or
no (object)
    <p>The caster creates an area of total darkness. The darkness is
impenetrable to normal vision and darkvision, but the caster can see
normally within the blacklit area. Creatures outside the spell&rsquo;s
area, even the caster, cannot see through it.</p>
    <p>The spell can be cast on a point in space, but the effect is
stationary cast on a mobile object. A character can cast the spell on a
creature, and the effect then radiates from the creature and moves as
it moves. Unattended objects and points in space do not get saving
throws or benefit from spell resistance.</p>
    <p>Blacklight counters or dispels any light spell of equal or
lower level. The 3rd-level cleric spell <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#daylight">daylight</a>
counters or dispels
blacklight.</p>
    <h6><a id="bolt-of-glory"></a>Bolt Of Glory</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Evocation [Good]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Glory 6
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S , D F
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./level)
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> Ray
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
    <p>This spell projects a bolt of energy from the Positive Energy
Plane against one creature. The caster must succeed at a ranged touch
attack to strike the target. A creature struck suffers varying damage,
depending on its nature and home plane of existence:</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "verticalAlign": "bottom",
            "width": "350px"
          }}>Creature's
Origin/Nature
          </th>
          <th style={{
            "width": "100px"
          }}>Damage</th>
          <td><span style={{
              "fontWeight": "bold"
            }}>Maximum Value</span></td>
        </tr>
        <tr className="odd-row">
          <td>Material Plane, Elemental Plane, neutral outsider</td>
          <td>1d6/2 levels</td>
          <td>7d6</td>
        </tr>
        <tr>
          <td>Negative Energy Plane, evil outsider, undead creature</td>
          <td>1d6/level</td>
          <td>15d6</td>
        </tr>
        <tr className="odd-row">
          <td>Positive Energy Plane, good outsider</td>
          <td>-</td>
          <td>-</td>
        </tr>
      </tbody>
    </table>
    <h6><a id="bolts-of-bedevilment"></a>Bolts Of Bedevilment</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Enchantment [Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Madness 5
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> One action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Medium (100 ft. +
10 ft./level)
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> Ray
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will negates
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
    <p>This spell grants the caster the ability to make one ray
attack per round. The ray <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#dazed">dazes</a> one living
creature, clouding its mind
so that it takes no action for 1d3 rounds. The creature is not <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#stunned">stunned</a>
(so attackers get no special advantage against it), but it can&rsquo;t
move, cast spells, use mental abilities, and so on.</p>
    <h6><a id="crown-of-glory"></a>Crown Of Glory</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Evocation</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Glory 8
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S , M, D F
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 full round
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> 120-ft.-radius
emanation centered on you
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 minute/level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will negates
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
    <p>The caster is imbued with an aura of celestial authority,
inspiring awe in all lesser creatures.</p>
    <p>The caster gains a +4 enhancement bonus to his or her Charisma
score for the duration of the spell. All creatures with fewer than 8 HD
or levels cease whatever they are doing and are compelled to pay
attention to the caster. Any such creature that wants to take hostile
action against the caster must make a successful Will save to do so.
Any creature that does not make this saving throw the first time it
attempts a hostile action is enthralled for the duration of the spell
(as the <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#enthrall">enthrall</a> spell), as long as it is
in the spell&rsquo;s area,
nor will it try to leave the area on its own. Creatures with 8 HD or
more may pay attention to the caster, but are not affected by this
spell.</p>
    <p>When the caster speaks, all listeners telepathically
understand him or her, even if they do not understand the language.
While the spell lasts, the caster can make up to three suggestions to
creatures of fewer than 8 HD in range, as if using the <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsS.html#mass-suggestion">mass
suggestion</a>
spell (Will save negates); creatures with 8 HD or more aren&rsquo;t
affected by this power. Only creatures within range at the time a
suggestion is given are subject to it.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Material Component:</span>
worth at least 200 gp.</p>
    <h6><a id="genesis"></a>Genesis</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Creation)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Creation 9
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S , M, X P
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 week (8
hours/day)
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 180 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> A demiplane on
the Ethereal Plane centered on your location
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No
    <p>This spell creates an immobile, finite plane with limited
access - a demi-plane.</p>
    <p>Demiplanes created by this power are very small, minor planes.
A character can cast this spell only on the Ethereal Plane. When he or
she casts it, a local density fluctuation precipitates the creation of
a demiplane. At first, the demiplane grows at a rate of a 1-foot-radius
per day to an initial maximum radius of 180 feet as the fledgling plane
draws substance from surrounding ethereal vapors and protomatter or
astral ectoplasm.</p>
    <p>The character determines the environment in the demiplane when
he or she first casts genesis, reflecting most any desire he or she can
visualize. The caster determines factors such as atmosphere, water,
temperature, and the shape of the general terrain. However, the spell
cannot create life, nor can it create construction. If desired, these
must be brought in by some other fashion.</p>
    <p>Once the basic demiplane reaches its maximum size, the
character can continue to cast this spell to enlarge the demiplane if
he or she is inside the boundaries of the demiplane. In such a case,
the radiusof the demiplane increases by 60 feet for each subsequent
casting.</p>
    <p>If the spell is cast again while outside an existing
demi-plane, the casting creates a separate bubble that does not touch
or overlap any previously created demiplane.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 5,000 XP.</p>
    <h6><a id="hardening"></a>Hardening</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Transmutation</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Sor/Wiz 6,
Artifice 7
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One item of a
volume no greater than 10 cu. ft./level (see text)
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Permanent
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
(object)
    <p>This spell increases the hardness of materials.&nbsp; For
every two caster levels, increase by 1 the hardness of the material
targeted by the spell. This hardness increase improves only the
material&rsquo;s resistance to damage. Nothing else is modified by the
improvement.</p>
    <p>The hardening spell does not in any way affect resistance to
other forms of transformation. </p>
    <p>This spell affects up to 10 cubic feet per level of the
spellcaster.</p>
    <p>If cast upon a metal or mineral, the volume is reduced to 1
cubic foot per level.</p>
    <h6><a id="maddening-scream"></a>Maddening Scream</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Enchantment (Compulsion)
[Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Sor/Wiz 8, Madness
8
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> One action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Living creature
touched
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1d4+1 rounds
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
    <p>The subject cannot keep him or herself from behaving as though
completely mad. This spell makes it impossible for the victim to do
anything other than race about caterwauling.</p>
    <p>The effect worsens the Armor Class of the creature by 4, makes
Reflex saving throws impossible except on a roll of 20, and makes it
impossible to use a shield.</p>
    <h6><a id="status-greater"></a>Status, Greater</h6>STATUS, GREATER
    <i>Divination</i>
    <b>Level:</b> Community 4
    <b>Components:</b> V, S, DF
    <b>Casting Time:</b> 1 standard action
    <b>Range:</b> Touch
    <b>Targets:</b> One creature touched/three levels
    <b>Duration:</b> 1 hour/level
    <b>Saving Throw:</b> Will negates (harmless)
    <b>Spell Resistance:</b> Yes (harmless)
    <p>{`As `}<a style={{
        "color": "#579eb6"
      }} href="spellsS.html#status">{`status`}</a>{`, but you can also
cast a limited selection of spells through the link, as if you were
touching the target. You can cast any spell that meets the following
conditions:`}</p>
    <ul>
      <li>Level: 0, 1st, or 2nd</li>
      <li>Range: Touch</li>
      <li>Target: Creature touched</li>
      <li>Saving Throw: harmless</li>
    </ul>
For example, if you become aware (through the greater status
spell) that one of your linked companions is dying, you can cast
    <a style={{
      "color": "#579eb6"
    }} href="spellsC.html#cure-moderate-wounds">cure moderate wounds</a> to try to revive her.
    <h6><a id="surelife"></a>Surelife</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Abjuration</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Repose 8
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, M
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 round
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 minute/2
levels
    <p>This spell allows the caster to protect him or herself against
some condition that would ordinarily cause certain death. The character
can only protect him or herself against a natural occurrence or
condition, not against a spell or the action of a creature. The
character must specify the condition against which he or she wishes to
protect him or herself, and the spell is effective only against that
condition. Should the character be subjected to that condition during
the duration of the spell, he or she feels no discomfort and takes no
damage from the condition. However, the spell does not protect any
items carried on the caster&rsquo;s person. At the end of the
spell&rsquo;s duration, the condition has full normal effects if the
character is still subjected to it.</p>
    <h6><a id="touch-of-madness"></a>Touch Of Madness</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Enchantment [Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Madness 2
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> One action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Creature touched
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will negates
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
    <p>The caster may <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#dazed">daze</a> one living creature
by making a successful
touch attack. If the target creature does not make a successful Will
save, its mind is clouded and it takes no action for 1 round per caster
level. The dazed subject is not <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#stunned">stunned</a> (so attackers
get no special
advantage against it), but it can&rsquo;t move, cast spells, use mental
abilities, and so on.</p>
    <h6><a id="true-creation"></a>True Creation</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Creation)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Creation 8
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S , M, X P
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 10 minutes
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 0 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> Unattended,
nonmagical object of nonliving matter, up to 1 cu. ft./level
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No
    <p>The caster creates a nonmagical, unattended object of any sort
of matter. Items created are permanent and cannot be negated by
dispelling magics or negating powers. For all intents and purposes,
these items are completely real. The volume of the item created cannot
exceed 1 cubic foot per caster level. The caster must succeed at an
appropriate skill check to make a complex item.</p>
    <p>Unlike the items brought into being by the lower-level spells
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#minor-creation">minor creation</a> and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsMtoO.html#major-creation">major
creation</a>, objects created by the casting of
true creation can be used as material components.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> The
item&rsquo;s gold piece value in XP, or a minimum of 1 XP, whichever is
more.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      